// This is only available to Surgeon Office organizations

import gql from 'graphql-tag';

export const fragments = {
  formBase: gql`
    fragment FormBase on FormSigning {
      id
      order
      name
      shouldSign
      isSigned
    }
  `,
  view: gql`
    fragment FormView on Form {
      id
      order
      name
      content
      value
      isSigned
      shouldSign
      signature
    }
  `,
  formPreview: gql`
    fragment FormView on Form {
      id
      order
      name
      content
      value
      isSigned
      signature
    }
  `,
};

export const forms = gql`
  query procedureForms($procedureId: Long) {
    procedureForms(procedureId: $procedureId) {
      ...FormBase
    }
  }
  ${fragments.formBase}
`;

export const signature = gql`
  query signature($procedureId: Long) {
    signature(procedureId: $procedureId)
  }
`;

export const form = gql`
  query previewFormDraft($id: Long!, $procedureId: Long) {
    previewFormDraft(id: $id, procedureId: $procedureId) {
      ...FormView
    }
  }
  ${fragments.formPreview}
`;

export const signForms = gql`
  mutation signForms($formIds: [Long!]!, $signature: String!) {
    signForms(formIds: $formIds, signature: $signature) {
      ...FormBase
    }
  }
  ${fragments.formBase}
`;

export const clearSignature = gql`
  mutation clearSignature($procedureId: Long) {
    clearSignature(procedureId: $procedureId) {
      ...FormBase
    }
  }
  ${fragments.formBase}
`;

export const uploadFormTypesForProcedure = gql`
  query uploadFormTypesForProcedure($procedureId: Long) {
    uploadFormTypesForProcedure(procedureId: $procedureId) {
      ...FormView
      uploads
    }
  }
  ${fragments.view}
`;

export const uploadRequest = gql`
  query uploadRequest($id: Long!, $procedureId: Long, $fileName: String!, $contentType: String!) {
    uploadRequest(id: $id, procedureId: $procedureId, fileName: $fileName, contentType: $contentType)
  }
`;

export const uploadedForms = gql`
  query uploadedForms($id: Long!, $procedureId: Long) {
    uploadedForms(id: $id, procedureId: $procedureId)
  }
`;

export const confirmFormUpload = gql`
  mutation confirmFormUpload($id: Long!, $procedureId: Long, $fileName: String!, $value: Json!) {
    confirmFormUpload(id: $id, procedureId: $procedureId, fileName: $fileName, value: $value)
  }
`;

export const removeUploadedForm = gql`
  mutation removeUploadedForm($id: Long!, $procedureId: Long, $fileName: String!, $value: Json!) {
    removeUploadedForm(id: $id, procedureId: $procedureId, fileName: $fileName, value: $value)
  }
`;
