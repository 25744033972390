import React, { useContext, useMemo, useRef, useState } from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import FormContext from '../FormContext';
import InputWrapper from '../InputWrapper';
import InputLabel from '../InputLabel';
import makeName from '../makeName';
import { FormattedMessage } from 'react-intl';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TranslatedMessage from '../TranslatedMessage';
import { FieldType, validatorFn, ValidatorType } from '../utils/validators/validateRequired';
import ValidationWrapper from './ValidationWrapper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(0, -1),

    '& .MuiTextField-root': {
      margin: theme.spacing(0, 1),
    },
  },
}));

const BodyMassIndexInput = ({ explanation, validators }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const { name, value, onChange } = useContext(FormContext);

  const heightInRef = useRef();
  const weightLbRef = useRef();

  const heightFtName = makeName(name, 'heightFt');
  const heightInName = makeName(name, 'heightIn');
  const weightLbName = makeName(name, 'weightLb');

  const heightFt = get(value, ['bodyMassIndex', 'heightFt'], '');
  const heightIn = get(value, ['bodyMassIndex', 'heightIn'], '');
  const weightLb = get(value, ['bodyMassIndex', 'weightLb'], '');

  const handleChange = (name, min, max) => e => {
    const nextInputvalue = e.target.value.replace(/\D/g, '');
    if (isFinite(min) && isFinite(max)) {
      const numericalNextInputValue = parseInt(nextInputvalue, '10');
      if (numericalNextInputValue < min || numericalNextInputValue > max) {
        return;
      }
    }
    if (name === 'heightFt') {
      if (nextInputvalue.length >= e.target.maxLength && heightInRef.current) {
        heightInRef.current.focus();
        heightInRef.current.setSelectionRange(0, heightInRef.current.value.length);
      }
    } else if (name === 'heightIn') {
      if (nextInputvalue.length >= e.target.maxLength && weightLbRef.current) {
        weightLbRef.current.focus();
        weightLbRef.current.setSelectionRange(0, weightLbRef.current.value.length);
      }
    }
    const nextValue = set(value ? cloneDeep(value) : {}, ['bodyMassIndex', name], nextInputvalue);
    const ok = (name, length) => get(nextValue, ['bodyMassIndex', name], '').length >= length;
    const done = ok('heightFt', 1) && ok('heightIn', 1) && ok('weightLb', 1);
    onChange(cloneDeep(set(nextValue, 'progress', done ? 1 : 0)));
  };

  const bmiObj = useMemo(() => ({ heightFt, heightIn, weightLb }), [heightFt, heightIn, weightLb]);
  const rules = validators ? validators.map(type => validatorFn[type]?.(FieldType.BodyMassIndex) ?? (() => '')) : [];
  const required = !!validators?.find(e => e === ValidatorType.isRequired);

  return (
    <FormControl>
      <ValidationWrapper value={bmiObj} rules={rules} error={error} setError={setError}>
        <InputWrapper>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel htmlFor={heightFt && !heightIn ? heightInName : heightFtName}>
                <FormattedMessage id="questionnaire.bmi.height" defaultMessage="Height" />
              </InputLabel>
              <div className={classes.root}>
                <TextField
                  variant="filled"
                  label={<FormattedMessage id="questionnaire.bmi.feet" defaultMessage="Feet" />}
                  type="text"
                  id={heightFtName}
                  name={heightFtName}
                  value={heightFt}
                  onChange={handleChange('heightFt')}
                  required={required}
                  error={!!error}
                  inputProps={{
                    maxLength: 1,
                  }}
                />
                <TextField
                  variant="filled"
                  type="text"
                  label={<FormattedMessage id="questionnaire.bmi.inches" defaultMessage="Inches" />}
                  inputRef={heightInRef}
                  id={heightInName}
                  name={heightInName}
                  value={heightIn}
                  onChange={handleChange('heightIn', 0, 12)}
                  required={required}
                  error={!!error}
                  inputProps={{
                    maxLength: 2,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <InputLabel htmlFor={weightLbName}>
                <FormattedMessage id="questionnaire.bmi.weight" defaultMessage="Weight" />
              </InputLabel>
              <TextField
                type="text"
                variant="filled"
                label={<FormattedMessage id="questionnaire.bmi.pounds" defaultMessage="Pounds" />}
                inputRef={weightLbRef}
                id={weightLbName}
                name={weightLbName}
                value={weightLb}
                onChange={handleChange('weightLb')}
                required={required}
                error={!!error}
                maxLength={3}
                fullWidth
              />
            </Grid>
          </Grid>
          {!!error && (
            <FormHelperText style={{ color: 'red' }}>
              <TranslatedMessage message={error} />
            </FormHelperText>
          )}
          {explanation && (
            <FormHelperText variant="filled">
              <TranslatedMessage message={explanation} />
            </FormHelperText>
          )}
          {/*{get(value, 'progress', 0) > 0 && '✅'}*/}
        </InputWrapper>
      </ValidationWrapper>
    </FormControl>
  );
};

BodyMassIndexInput.defaultValue = { bodyMassIndex: {}, progress: 0 };

export default BodyMassIndexInput;
