import React, { useMemo, useState } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import FormContext from '../FormContext';
import renderFormNode from '../renderFormNode';
import components from '../components';
import InputWrapper from '../InputWrapper';
import Question from '../Question';
import { FormattedMessage } from 'react-intl';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from '@material-ui/icons';
import TranslatedMessage from '../TranslatedMessage';
import DistinguishTitle from '../DistinguishTitle';
import { FieldType, validatorFn, ValidatorType } from '../utils/validators/validateRequired';
import ValidationWrapper from './ValidationWrapper';

export const NestedFormWrapper = withStyles(theme => ({
  root: {
    borderLeft: `0.125em solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),

    '&:empty': {
      margin: 0,
    },

    '& > *:last-of-type': {
      marginBottom: 0,
    },
  },
}))(Box);

const makeName = (name, key) => (name ? `${name}.${key}` : key);

const YesNoInput = ({ question, ifYes, ifNo, distinguish, explanation, validators }) => {
  const [error, setError] = useState(null);

  const rules = validators ? validators.map(type => validatorFn[type]?.(FieldType.YesNo) ?? (() => '')) : [];
  const required = !!validators?.find(e => e === ValidatorType.isRequired);

  const ifYesNode = useMemo(() => ifYes && React.cloneElement(renderFormNode(ifYes), { omitnone: 'true' }), [ifYes]);
  const ifNoNode = useMemo(() => ifNo && React.cloneElement(renderFormNode(ifNo), { omitnone: 'true' }), [ifNo]);

  return (
    <InputWrapper>
      {distinguish && <DistinguishTitle title={distinguish} />}
      <Question>
        <FormattedMessage id={question} defaultMessage={question} />
        &nbsp;{required && '*'}
      </Question>
      <FormContext.Consumer>
        {({ name, value, onChange }) => {
          const handleChange = value => {
            let progress = 0;

            if (isArray(value)) {
              if (value[0] === true || value[0] === false) {
                progress += 1;
              }
              if (value[0] === true) {
                progress += get(value, [1, 'progress'], 0) + get(ifNo, 'weight', 0);
              }
              if (value[0] === false) {
                progress += get(value, [1, 'progress'], 0) + get(ifYes, 'weight', 0);
              }
            } else {
              if (value === true || value === false) {
                progress += 1;
              }
              if (value === true) {
                progress += get(value, 'progress', 0) + get(ifNo, 'weight', 0);
              }
              if (value === false) {
                progress += get(value, 'progress', 0) + get(ifYes, 'weight', 0);
              }
            }

            onChange({ yes: value, progress });
          };

          const handleYesChange = e => {
            if (ifYes) {
              handleChange(e.target.checked ? [true, (components()[ifYes['type']] || {}).defaultValue] : null);
            } else {
              handleChange(e.target.checked || null);
            }
          };

          const handleNoChange = e => {
            if (ifNo) {
              handleChange(e.target.checked ? [false, (components()[ifNo['type']] || {}).defaultValue] : null);
            } else {
              handleChange(e.target.checked ? false : null);
            }
          };

          const yes = value?.yes;
          const yesValue = (isArray(yes) ? yes[0] : yes) === true;
          const noValue = (isArray(yes) ? yes[0] : yes) === false;

          return (
            <ValidationWrapper value={isArray(yes) ? yes[0] : yes} rules={rules} error={error} setError={setError}>
              {/*{get(value, 'progress', 0) > 0 && '✅'}*/}
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    label={<FormattedMessage id="questionnaire.yesno.no" defaultMessage="No" />}
                    control={
                      <Checkbox
                        icon={<CheckBoxOutlineBlankSharp fontSize="large" />}
                        checkedIcon={<CheckBoxSharp fontSize="large" />}
                        type="checkbox"
                        name={name}
                        value="No"
                        checked={noValue}
                        onChange={handleNoChange}
                      />
                    }
                  />
                  <FormControlLabel
                    label={<FormattedMessage id="questionnaire.yesno.yes" defaultMessage="Yes" />}
                    control={
                      <Checkbox
                        icon={<CheckBoxOutlineBlankSharp fontSize="large" />}
                        checkedIcon={<CheckBoxSharp fontSize="large" />}
                        type="checkbox"
                        name={name}
                        value="Yes"
                        checked={yesValue}
                        onChange={handleYesChange}
                      />
                    }
                  />
                </FormGroup>
                {!!error && (
                  <FormHelperText style={{ color: 'red' }}>
                    <TranslatedMessage message={error} />
                  </FormHelperText>
                )}
                {explanation && (
                  <FormHelperText>
                    <TranslatedMessage message={explanation} />
                  </FormHelperText>
                )}
              </FormControl>

              {noValue && (
                <NestedFormWrapper>
                  <FormContext.Provider
                    value={{
                      name: makeName(name, 'yes'),
                      value: yes[1],
                      onChange: v => handleChange([false, v]),
                    }}
                  >
                    {ifNoNode}
                  </FormContext.Provider>
                </NestedFormWrapper>
              )}

              {yesValue && (
                <NestedFormWrapper>
                  <FormContext.Provider
                    value={{
                      name: makeName(name, 'no'),
                      value: yes[1],
                      onChange: v => handleChange([true, v]),
                    }}
                  >
                    {ifYesNode}
                  </FormContext.Provider>
                </NestedFormWrapper>
              )}
            </ValidationWrapper>
          );
        }}
      </FormContext.Consumer>
    </InputWrapper>
  );
};

YesNoInput.defaultValue = { progress: 0 };

export default YesNoInput;
