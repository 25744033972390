import gql from 'graphql-tag';

export const caretakerConfirmation = gql`
  mutation saveCaretakerPickupMessage($content: String) {
    saveCaretakerPickupMessage(content: $content)
  }
`;

export const patientPickupMessage = gql`
  query getPatientPickupMessage {
    getPatientPickupMessage {
      id
      name
      content
      description
      trigger
      destination
      subject
      channel
      enabled
      additionalInfo
    }
  }
`;
