import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { H2, Header } from '../QuestionnaireStartPage';
import { useQuery } from 'react-apollo-hooks';
import { form as formQuery } from '../../../graphql/schema/form';
import { ClipLoader } from 'react-spinners';

import ReactHtmlParser from 'react-html-parser';
import Frame from 'react-frame-component';
import get from 'lodash/get';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import PdfViewer from '../../../components/PdfViewer';

const pageSize = {
  width: 215.9,
  height: 279.4,
};

const scale = 0.8;

const StyledFrame = styled(Frame)`
  width: ${pageSize.width}mm;
  max-width: ${pageSize.width}mm;
  height: ${pageSize.height}mm;
  font-size: ${scale}rem;
`;

const head = ReactHtmlParser(`
  <style>
    html {
      background: white;
    }
    body {
      margin: 0;
      transform-origin: 0 0;
    }
    .page {
      width: ${pageSize.width}mm;
      max-width: ${pageSize.width}mm;
      min-height: ${pageSize.height}mm;

      display: flex;
      justify-content: center;
      background: white;
      color: black;
      padding: 1rem;
      overflow: hidden;
      border: 2px solid black;
    }

    .page + .page {
      margin-top: 2rem;
    }

    @media print {
      @page {
        width: ${pageSize.width}mm;
        height: ${pageSize.height}mm;
        margin: 0;
      }

      .page {
        width: ${pageSize.width}mm;
        height: ${pageSize.height}mm;
        max-height: ${pageSize.height}mm;
        font-size: 1em;
        box-shadow: none;
        border: none;
        padding: 2rem;
      }
      body {
        transform: scale(1) !important;
      }
    }
  </style>
`);

const Form = React.forwardRef(({ content, value, signature }, ref) => {
  const transform = useCallback(
    node => {
      const { type, name } = node;
      if (type === 'tag' && name === 'input') {
        const { id, type } = node.attribs;

        if (type === 'radio') {
          const fieldVal = get(value, node.attribs.name);
          const attrs = { checked: String(fieldVal) === node.attribs.value };
          return <input {...node.attribs} key={`${node.attribs.name}-${id}`} {...attrs} />;
        } else if (type === 'checkbox') {
          const fieldVal = get(value, id);
          const attrs = { checked: !!fieldVal };
          return <input {...node.attribs} key={id} {...attrs} />;
        } else {
          const fieldVal = get(value, id);
          const attrs = { value: fieldVal || '' };
          return <input {...node.attribs} key={id} {...attrs} />;
        }
      } else if (type === 'tag' && name === 'textarea') {
        const { id } = node.attribs;
        return <textarea {...node.attribs} value={get(value, id)} key={id} />;
      } else if (type === 'tag' && name === 'img' && 'data-signature' in node.attribs && signature) {
        return <img src={signature} {...node.attribs} alt={'patient signature'} />;
      }
    },
    [value, signature]
  );

  const node = useMemo(() => ReactHtmlParser(content, { transform }), [content, transform]);

  useEffect(() => {
    const curr = get(ref, 'current.node');
    if (curr) {
      setTimeout(() => {
        curr.style.height = curr.contentWindow.document.documentElement.scrollHeight + 'px';
        curr.style.width = curr.contentWindow.document.documentElement.scrollWidth + 'px';

        const containerWidth = Math.min(
          get(window.document.documentElement.getElementsByClassName('Page Disclaimer'), '[0].scrollWidth', 0),
          window.innerWidth
        );
        const scale = (containerWidth * 0.8) / curr.contentWindow.document.documentElement.scrollWidth;
        const bodyEl = curr.contentWindow.document.getElementsByTagName('body');

        if (bodyEl && bodyEl.length > 0) {
          curr.style.height = curr.contentWindow.document.documentElement.scrollHeight * scale + 'px';
          bodyEl[0].style.transform = `scale(${scale})`;
        }
      }, 50);
    }
  }, [content, ref]);

  return (
    <StyledFrame ref={ref} head={head} frameBorder="0" scrolling="no">
      {node}
    </StyledFrame>
  );
});

const Preview = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: auto;

  > iframe {
    overflow: auto;
  }
`;

const FormPreview = ({ id, procedureId, onBack }) => {
  const {
    loading,
    data: { previewFormDraft: form = {} },
  } = useQuery(formQuery, { variables: { id, procedureId }, fetchPolicy: 'network-only' });

  const ref = useRef();

  if (loading) {
    return (
      <div className="Page PageCenter" style={{ alignItems: 'center' }}>
        <ClipLoader />
      </div>
    );
  }
  const value = JSON.parse(form.value || '{}');

  const printForm = () => {
    const curr = get(ref, 'current.node');
    if (curr) {
      try {
        curr.contentWindow.focus();
        curr.contentWindow.print();
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className="Page Disclaimer">
      <Header style={{ display: 'flex' }}>
        <span style={{ display: 'flex' }}>
          <IconButton onClick={onBack} size={'medium'}>
            <ArrowBackIcon />
          </IconButton>
          <H2 style={{ textAlign: 'center' }}>{form.name}</H2>
        </span>
        <IconButton onClick={printForm}>
          <PrintIcon />
        </IconButton>
      </Header>
      <Preview>{form && <Form content={form.content} value={value} signature={form.signature} ref={ref} />}</Preview>
    </div>
  );
};

export default FormPreview;

export const PdfPreview = ({ url, onBack }) => (
  <div className="Page Disclaimer" style={{ padding: 0 }}>
    <Header style={{ display: 'flex' }}>
      <span style={{ display: 'flex' }}>
        <IconButton onClick={onBack} size={'medium'}>
          <ArrowBackIcon />
        </IconButton>
      </span>
    </Header>
    <Preview style={{ margin: 0 }}>
      <PdfViewer width={window.innerWidth < 800 ? 550 : undefined} url={url} />
    </Preview>
  </div>
);
