import React from 'react';
import { hospital } from '../../graphql/schema/familyMember';
import get from 'lodash/get';
import { Query } from 'react-apollo';
import { Button, Container, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import illustration from '../../assets/images/checkmark.svg';
import { FormattedMessage } from 'react-intl';
import Header from './Header';
import { Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
});

const SuccessItem = ({ text }) => (
  <ListItem>
    <ListItemIcon>
      <Check color="inherit" />
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);

const QuestionnaireSuccessState = ({ onBack, progress, hasDocumentsForUpload, hasSignature }) => {
  const classes = useStyles();

  return (
    <Query query={hospital}>
      {({ data: { patientHospital: hospital } }) => (
        <Container maxWidth="sm" className={classes.container}>
          <Header
            hospitalName={get(hospital, 'name')}
            contact={get(hospital, 'contact')}
            address={get(hospital, 'address')}
          />

          <Box mb={2}>
            <img src={illustration} alt="Ospitek thank you" style={{ maxHeight: '10rem' }} />
          </Box>
          <Typography variant="h3">
            <FormattedMessage id="questionnaire.success.thanks" defaultMessage="Thank you!" />
          </Typography>
          <Box color="success.main" my={1}>
            <List>
              {hasDocumentsForUpload && (
                <SuccessItem
                  text={
                    <FormattedMessage id="questionnaire.success.docs.uploaded" defaultMessage="Documents uploaded." />
                  }
                />
              )}
              {hasSignature && (
                <SuccessItem
                  text={
                    <FormattedMessage
                      id="questionnaire.success.signature.submitted"
                      defaultMessage="Signature submitted."
                    />
                  }
                />
              )}
              <SuccessItem
                text={
                  <FormattedMessage
                    id="questionnaire.success.questionnaire.submitted"
                    defaultMessage="The questionnaire was submitted successfully {progress}%"
                    values={{ progress: Math.ceil(progress) }}
                  />
                }
              />
            </List>
          </Box>
          <Typography color="textSecondary">
            <FormattedMessage
              id="questionnaire.success.staff.call"
              defaultMessage="A staff member or nurse may call you for further clarification."
            />
          </Typography>
          <Box mt={4}>
            <Button onClick={onBack}>
              <FormattedMessage id="questionnaire.success.back" defaultMessage="Go Back to Form" />
            </Button>
          </Box>
        </Container>
      )}
    </Query>
  );
};

export default QuestionnaireSuccessState;
