import { Messages } from '../types/localization';

const ar: Messages = {
  'homepage.instructions': 'الرجاء إدخال رقم التعريف الشخصي',
  'homepage.error.pin': 'رقم التعريف الشخصي غير صالح ، يرجى المحاولة مرة أخرى.',
  'homepage.error.unexpected': 'حدث خطأ غير متوقع ، يرجى المحاولة مرة أخرى في وقت لاحق.',
  'homepage.helper':
    'يمكنك العثور على رقم التعريف الشخصي في الرسالة النصية التي تلقيتها من مستشفى المركز الطبي الدولي.',
  'status.patient': 'المريض',
  'status.physician': 'الطبيب',
  'status.status': 'الحالة',
  'status.time': 'الوقت',
  'status.admitted': 'تم الدخول',
  'status.preparing-for-surgery': 'التجهيز للجراحة',
  'status.in-surgery': 'في الجراحة',
  'status.in-recovery': 'في الإفاقة',
  'status.in-postop': 'تحضيرات ما قبل العملية',
  'status.able-to-visit': 'Able to visit',
  'status.final.title': 'انتهت زيارة المريض',
  'status.final.subtitle': 'شكراً لإتاحة الفرصة لنا للتطوير.',
  'hospital.call': 'اتصل',
  'hospital.directions': 'الاتجاه',
  'feedback.error': 'كان هناك خطأ اعتماد النموذج. حاول مرة اخرى. إذا استمرت المشكلة ، فاتصل بموظفي مكتب المساعدة.',
  'feedback.share.question': 'هل ترغب في مشاركة تجربتك مع الآخرين؟',
  'feedback.share.google': 'فضلاً قم بتسجيل تقييم على جوجل',
  'feedback.share.yelp': 'قيم تجربتك على Yelp!',
  'feedback.share.skip': 'تخطي',
  'feedback.suggestion.question': 'هل ترغب في ترك ملاحظة أو إخبارنا كيف يمكننا تحسين تجربتك؟',
  'feedback.send': 'فضلاً.. يهمنا رأيك',
  'feedback.success.title': 'شكرًا',
  'feedback.success.text': 'تم إرسال اقتراحك',
  'rating.instructions': 'قيِّم تجربتك',
  'rating.thanks': 'شكرًا',
  'This field is required': 'يجب ملء هذا الخط',
  'Please fill in the required fields': 'يرجى تعبئة الحقول المطلوبة',
  'caretaker.ready-to-pick-up': 'Patient is ready to be picked up!',
  'caretaker.type-here': 'Type here...',
  'caretaker.confirm': 'Confirm',
  'caretaker.thanks': 'شكرًا',
  'caretaker.successfully-sent': 'Your message was sent successfully.',
};

export default ar;
