import React, { useContext, useMemo, useRef, useState } from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import FormContext from '../FormContext';
import InputWrapper from '../InputWrapper';
import InputLabel from '../InputLabel';
import makeName from '../makeName';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TranslatedMessage from '../TranslatedMessage';
import { FieldType, validatorFn, ValidatorType } from '../utils/validators/validateRequired';
import ValidationWrapper from './ValidationWrapper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(0, -1),

    '& .MuiTextField-root': {
      margin: theme.spacing(0, 1),
    },
  },
}));

const DateOfBirthInput = ({ optional, explanation, validators }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [error, setError] = useState(null);
  const { name, value, onChange } = useContext(FormContext);

  const dayRef = useRef();
  const yearRef = useRef();

  const monthName = makeName(name, 'month');
  const dayName = makeName(name, 'day');
  const yearName = makeName(name, 'year');

  const month = get(value, ['dateOfBirth', 'month'], '');
  const day = get(value, ['dateOfBirth', 'day'], '');
  const year = get(value, ['dateOfBirth', 'year'], '');

  const handleChange = (name, min, max) => e => {
    const nextInputvalue = e.target.value.replace(/\D/g, '');
    const numericalNextInputValue = parseInt(nextInputvalue, '10');
    if (numericalNextInputValue < min || numericalNextInputValue > max) {
      return;
    }
    if (name === 'month') {
      if (nextInputvalue.length >= e.target.maxLength && dayRef.current) {
        dayRef.current.focus();
        dayRef.current.setSelectionRange(0, dayRef.current.value.length);
      }
    } else if (name === 'day') {
      if (nextInputvalue.length >= e.target.maxLength && yearRef.current) {
        yearRef.current.focus();
        yearRef.current.setSelectionRange(0, yearRef.current.value.length);
      }
    }
    const nextValue = set(value ? cloneDeep(value) : {}, ['dateOfBirth', name], nextInputvalue);
    const ok = (name, length) => get(nextValue, ['dateOfBirth', name], '').length >= length;
    const done = ok('month', 1) && ok('day', 1) && ok('year', 4);
    onChange(cloneDeep(set(nextValue, 'progress', done ? (!optional ? 1 : 0) : 0)));
  };

  const padDigits = field => e => {
    try {
      const val = e.target.value;

      if (val && val.length === 1) {
        e.target.value = `0${val}`;
      }

      handleChange(field)(e);
    } catch (e) {}
  };

  const handleYearBlur = e => {
    try {
      const val = e.target.value;
      if (val && val.length === 2) {
        const end00Year = new Date().getFullYear() % 1000;
        if (parseInt(val, 10) > end00Year) {
          e.target.value = `19${val}`;
        } else {
          e.target.value = `20${val}`;
        }
      }

      handleChange('year')(e);
    } catch (e) {}
  };

  const dateObj = useMemo(() => ({ year, month, day }), [year, month, day]);
  const rules = validators ? validators.map(type => validatorFn[type]?.(FieldType.Date) ?? (() => '')) : [];
  const required = !!validators?.find(e => e === ValidatorType.isRequired);

  return (
    <ValidationWrapper value={dateObj} rules={rules} error={error} setError={setError}>
      <InputWrapper>
        <FormControl variant="filled">
          <InputLabel htmlFor={!month ? monthName : !day ? dayName : !year ? yearName : monthName}>
            <FormattedMessage id="questionnaire.dob.title" defaultMessage="Date of Birth" />
          </InputLabel>

          <div className={classes.root}>
            <TextField
              placeholder={intl.formatMessage({ id: 'questionnaire.dob.placeholder.month', defaultMessage: 'MM' })}
              variant="filled"
              label={<FormattedMessage id="questionnaire.dob.month" defaultMessage="Month" />}
              type="text"
              id={monthName}
              name={monthName}
              value={month}
              onChange={handleChange('month', 0, 12)}
              onBlur={padDigits('month')}
              required={required}
              error={!!error}
              inputProps={{
                length: 2,
                maxLength: 2,
              }}
            />
            <TextField
              type="text"
              placeholder={intl.formatMessage({ id: 'questionnaire.dob.placeholder.day', defaultMessage: 'DD' })}
              variant="filled"
              label={<FormattedMessage id="questionnaire.dob.day" defaultMessage="Day" />}
              inputRef={dayRef}
              id={dayName}
              name={dayName}
              value={day}
              onChange={handleChange('day', 0, 31)}
              onBlur={padDigits('day')}
              required={required}
              error={!!error}
              inputProps={{
                length: 2,
                maxLength: 2,
              }}
            />
            <TextField
              type="text"
              variant="filled"
              label={<FormattedMessage id="questionnaire.dob.year" defaultMessage="Year" />}
              placeholder={intl.formatMessage({ id: 'questionnaire.dob.placeholder.year', defaultMessage: 'YYYY' })}
              inputRef={yearRef}
              id={yearName}
              name={yearName}
              value={year}
              onChange={handleChange('year', 0, new Date().getFullYear())}
              onBlur={handleYearBlur}
              required={required}
              error={!!error}
              inputProps={{
                length: 4,
                maxLength: 4,
              }}
            />
          </div>
          {!!error && (
            <FormHelperText style={{ color: 'red' }}>
              <TranslatedMessage message={error} />
            </FormHelperText>
          )}
          {explanation && (
            <FormHelperText>
              <TranslatedMessage message={explanation} />
            </FormHelperText>
          )}
        </FormControl>
      </InputWrapper>
    </ValidationWrapper>
  );
};

DateOfBirthInput.defaultValue = { dateOfBirth: {}, progress: 0 };

export default DateOfBirthInput;
