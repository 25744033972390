import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getGMapsLink } from '../PatientStatus/HospitalInformation';
import LocalePicker from '../../components/LocalePicker';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CallTwoTone, DirectionsTwoTone } from '@material-ui/icons';
import { useQuery } from 'react-apollo-hooks';
import { procedure } from '../../graphql/schema/familyMember';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  lead: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(3, -1),

    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
}));

const Header = ({ hospitalName, contact, address, procedureId }) => {
  const classes = useStyles();
  const { data, loading } = useQuery(procedure, {
    variables: {
      procedureId,
    },
  });
  const organizationLocation = data?.patientProcedure?.organizationLocation?.name;

  if (loading) {
    return null;
  }
  return (
    <Box mb={4}>
      <Box my={3} display="flex" justifyContent="center">
        <LocalePicker />
      </Box>
      {hospitalName && (
        <Typography variant="h2" align="center">
          {hospitalName}
        </Typography>
      )}
      {(contact || address) && (
        <div className={classes.actions}>
          {contact && (
            <Button startIcon={<CallTwoTone />} variant="outlined" component="a" href={`tel:${contact}`}>
              <FormattedMessage id="questionnaire.call" defaultMessage={`Call ${contact}`} />
            </Button>
          )}
          {(organizationLocation || address) && (
            <Button
              variant="outlined"
              startIcon={<DirectionsTwoTone />}
              component="a"
              href={getGMapsLink(organizationLocation || address)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="questionnaire.directions" defaultMessage="Directions" />
            </Button>
          )}
        </div>
      )}
    </Box>
  );
};

export default Header;
