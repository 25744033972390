import React, { FC, useCallback, useContext } from 'react';
import { Button, createStyles, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import Flag from 'react-flagkit';
import classNames from 'classnames';
import { LocaleContext } from './LocaleProvider';
import { Context as LocalizationContext, LocaleId } from '../types/localization';
import { ArrowDropDown } from '@material-ui/icons';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { ApolloContext } from 'react-apollo';
import { ApolloContextValue } from 'react-apollo/ApolloContext';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  compact?: boolean;
  isCaretaker?: boolean;
}

type LocalePickerFC = FC<Omit<Props, 'compact'> & LocalizationContext>;

const CompactLocalePicker: LocalePickerFC = ({ className, style, availableLocales, selectedLocale, selectLocale }) => {
  const handleSelectLocale = useCallback(
    (localeId: LocaleId) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      selectLocale(localeId);
      setAnchorEl(null);
    },
    [selectLocale]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        onClick={handleClick}
        startIcon={selectedLocale.id.country && <Flag country={selectedLocale.id.country as any} />}
        endIcon={<ArrowDropDown />}
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={className}
        style={style}
      >
        {selectedLocale.nativeName}
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {availableLocales.map(locale => (
          <MenuItem key={locale.id.toString()} onClick={handleSelectLocale(locale.id)}>
            {locale.id.country && <Flag country={locale.id.country as any} style={{ marginRight: '0.5em' }} />}
            {locale.nativeName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const RegularLocalePicker: LocalePickerFC = ({ className, style, availableLocales, selectLocale }) => {
  const classes = useStyles();

  const handleSelectLocale = useCallback(
    (localeId: LocaleId) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      selectLocale(localeId);
    },
    [selectLocale]
  );

  return (
    <ul className={classNames(classes.locales, className)} style={style}>
      {availableLocales.map(locale => (
        <li key={locale.id.toString()} className={classes.locale}>
          <Button
            variant="text"
            color="primary"
            onClick={handleSelectLocale(locale.id)}
            startIcon={locale.id.country && <Flag country={locale.id.country as any} />}
          >
            {locale.nativeName}
          </Button>
        </li>
      ))}
    </ul>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    locales: {
      listStyle: 'none',
      display: 'flex',
      padding: 0,
      margin: theme.spacing(0, -1),
    },
    locale: {
      margin: theme.spacing(0, 1),
    },
  })
);

type LocalePersistorFC = FC<
  Omit<Props, 'compact'> &
    Omit<LocalizationContext, 'selectLocale'> & {
      component: LocalePickerFC;
      useSelectLocale: () => (id: LocaleId) => void;
    }
>;

const LocalePersistor: LocalePersistorFC = ({ component: Component, useSelectLocale, ...props }) => {
  const setLocale = useSelectLocale();

  return <Component {...props} selectLocale={setLocale} />;
};

function provideUseSelectLocale(
  selectLocale: (id: LocaleId) => void,
  apolloContext: ApolloContextValue | undefined,
  isCaretaker: boolean
) {
  if (apolloContext === undefined) {
    return function useSelectLocale() {
      return selectLocale;
    };
  } else {
    return function useSelectLocale() {
      const setMyPreferredLanguage = useMutation(gql`
        mutation setMyPreferredLanguage($language: String!) {
          setMyPreferredLanguage(language: $language)
        }
      `);

      return useCallback(
        async (id: LocaleId) => {
          selectLocale(id);

          !isCaretaker &&
            (await setMyPreferredLanguage({
              variables: {
                language: id.language,
              },
            }));
        },
        [setMyPreferredLanguage]
      );
    };
  }
}

const LocalePicker: FC<Props> = ({ compact, isCaretaker = false, ...props }) => {
  const apolloContext = useContext(ApolloContext);
  const localizationContext = useContext(LocaleContext);

  if (localizationContext.availableLocales.length < 2) {
    return null;
  }

  return (
    <LocalePersistor
      component={compact ? CompactLocalePicker : RegularLocalePicker}
      useSelectLocale={provideUseSelectLocale(localizationContext.selectLocale, apolloContext, isCaretaker)}
      {...props}
      {...localizationContext}
    />
  );
};

export default LocalePicker;
